
@import "./abstracts/variables";


// @import "../node_modules/bootstrap/scss/functions";
// @import "../node_modules/bootstrap/scss/variables";

@import "~bootstrap/scss/bootstrap";
@import "bootstrap-icons";




header {
    position: relative;
    background-color: black;
    height: 75vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
  }
  
  header video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  
  header .container {
    position: relative;
    z-index: 2;
  }
  
  header .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 1;
  }

 .play-btn {
   position: relative;
   bottom: 20px;
   z-index: 50;
 }

 small {
  font-size: 10px;
 }


  
  /* Media Query for devices withi coarse pointers and no hover functionality */
  
  /* This will use a fallback image instead of a video for devices that commonly do not support the HTML5 video element */
  
  @media (pointer: coarse) and (hover: none) {
    header {
      background: url('../../files/fiberoptic-connector-contaminated.jpg') black no-repeat center center scroll;
    }
  
    header video {
      display: none;
    }
  }




.go-btn {
  color: $yellow-500;

}
.go-btn:hover {
   color: $yellow-700;

}

.wp-card {
  border: 1px solid $yellow-500;
  cursor: pointer;
}

.wp-card:hover {
  box-shadow: 0px 2px 15px rgb(194, 193, 193);
}

.tvid-card {
  cursor: pointer;
}

.sans {
  font-family: Arial, Helvetica, sans-serif;
}

#TrainingVideoModal .modal-dialog {
height: 100vh;
display: flex;
align-items: center;

}

#TrainingVideoModal .ratio {
  border: 5px solid black;
  background: black;
}

#TrainingVideoModal video {
  background: black;
}

.subscribe-btn:hover {
  box-shadow: 0px 4px 10px rgb(163, 151, 90);

}

#footer a {
  text-decoration: none;
}

.reveal .flyout-email {
  padding: 10px;
  display: inline-block;
  border-radius: 0 15px 15px 0;
  position: sticky;
  top: 50px;
  z-index: 100;
  transform: translateX(-180px);
  transition: 2.5s all ease;
  opacity: 0;
  box-shadow: 0 2px 10px rgb(145, 144, 144);
}

.reveal.active .flyout-email {
  transform: translateX(0px);
  opacity: 1;
}



